import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["defaultAccessPeriodPicker", 'startAtDatePicker', "endAtDatePicker"];

  switchToIndividualDates() {
    this.startAtDatePickerTarget.dispatchEvent(new CustomEvent('promote:datepicker-disable'));
    this.endAtDatePickerTarget.dispatchEvent(new CustomEvent('promote:datepicker-disable'));

    this.startAtDatePickerTarget.dispatchEvent(
      new CustomEvent('promote:datepicker-change', {detail: { value: '' } })
    );

    this.endAtDatePickerTarget.dispatchEvent(
      new CustomEvent('promote:datepicker-change', {detail: { value: '' } })
    );

    this.defaultAccessPeriodPickerTarget.dispatchEvent(
      new CustomEvent('promote:number-picker-enable')
    );
  }

  switchToProgramDates() {
    this.startAtDatePickerTarget.dispatchEvent(new CustomEvent('promote:datepicker-enable'));
    this.endAtDatePickerTarget.dispatchEvent(new CustomEvent('promote:datepicker-enable'));

    this.defaultAccessPeriodPickerTarget.dispatchEvent(
      new CustomEvent('promote:number-picker-disable')
    );
  }
}
